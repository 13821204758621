import React, { useEffect } from "react"
import lottie from "lottie-web"
import { document } from "browser-monads"

export default function SecondaryPageAnimation({ animation }) {
  useEffect(() => {
    const cont2 = document.getElementById("lottie2")

    lottie.loadAnimation({
      container: cont2,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: animation,
    })
  }, [animation])

  return (
    <div className="z-20 relative">
      <div id="lottie2" className=""></div>
    </div>
  )
}
