import React from "react"
import Button from "./Button"

export default function AsideColumnBox({ box }) {
  const buttons = box.knappar
  return (
    <div
      className={`${
        box.morkgul === true ? "bg-primary morkgul" : "bg-sixth "
      }  p-4 mb-4 gul-box`}
    >
      <div dangerouslySetInnerHTML={{ __html: box.text }} className="" />
      {buttons?.map((button, i) => {
        return <Button key={i} link={button.lank} text={button.text} />
      })}
    </div>
  )
}
