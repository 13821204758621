import React from "react"
import AsideColumnBox from "./AsideColumnBox"
import SecondaryPageAnimation from "./SecondaryPageAnimation"
export default function PageBody({ data }) {
  const { content } = data.wpPage
  const boxes = data.wpPage.innehall.gulBox
  const animation2 = data.wpPage.innehall.sekundaranimation?.localFile.publicURL

  return (
    <div
      id="wp-page"
      className="md:grid grid-cols-2 gap-8 space-y-4 lg:space-y-0 leading-relaxed"
    >
      <div className="">
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="space-y-4 left"
        />

        {animation2 && (
          <div className="my-8">
            <SecondaryPageAnimation animation={animation2} />
          </div>
        )}
      </div>
      <div>
        {boxes?.map((box, i) => {
          return <AsideColumnBox key={i} box={box} />
        })}
      </div>
      <blockquote className="hidden"></blockquote>
    </div>
  )
}
