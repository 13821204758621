import React, { useEffect } from "react"
import lottie from "lottie-web"
import { document } from "browser-monads"
export default function MainPageAnimation({ animation }) {
  useEffect(() => {
    const cont = document.getElementById("lottie")

    lottie.loadAnimation({
      container: cont,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: animation,
    })
  }, [animation])

  return (
    <div className="z-20 relative">
      <div id="lottie" className=""></div>
    </div>
  )
}
