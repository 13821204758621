import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import PageHeader from "../components/common/PageHeader"
import IngressPage from "../components/common/IngressPage"
import PageBody from "../components/common/PageBody"
import MainPageAnimation from "../components/common/MainPageAnimation"
import MainPageIllustration from "../components/common/MainPageIllustration"
import ill1 from "../images/illustrations/RevenueManagement.svg"
import Seo from "../components/seo"

export default function PageTemplate({ data }) {
  const lang = data.wpPage.language.slug
  const animation = data.wpPage.innehall.animation?.localFile.publicURL
  const translation = data.wpPage.translations[0]?.uri
  return (
    <div className="">
      <Seo title={data.wpPage.title} />
      <Layout lang={lang} translation={translation}>
        <div className="space-y-8">
          <PageHeader text={data.wpPage.title} />
          <IngressPage text={data.wpPage.innehall.ingress} />
          {animation ? (
            <MainPageAnimation animation={animation} />
          ) : (
            <MainPageIllustration img={ill1} />
          )}

          <PageBody data={data} />
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      language {
        slug
      }
      translations {
        uri
      }
      innehall {
        animation {
          localFile {
            relativePath
            publicURL
            absolutePath
          }
        }
        sekundarAnimation {
          localFile {
            relativePath
            publicURL
            absolutePath
          }
        }
        ingress
        gulBox {
          morkgul
          text
          knappar {
            lank
            text
          }
        }
      }
      translations {
        uri
      }
    }
  }
`
